@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto');

body, html {
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  min-height: 100vh;
  background: black;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.Container {
  display: flex;
  max-width: 1250px;
  height: 100vh;
  overflow: auto;
}

.Sidebar {
  width: 250px;
  height: 100vh;
}

.logo{
  position: relative;
  top: 30px;
  left: 80px;
}

.SidebarList{
  font-family: Roboto Flex;
  text-align: right;
  line-height: 350%;
  list-style: none;
  color: #AAAAAA;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  margin-top: 45px;
  white-space: nowrap;
  transform: translateX(-30px);
}

.SidebarList .HomeTitle:hover{
  color:white;
  cursor: pointer;
}

.SidebarList .UsersTitle:hover{
  color:white;
  cursor: pointer;
}

.SidebarList .VerifiedUsersTitle:hover{
  color:white;
  cursor: pointer;
}

.SidebarList .TrendingImagesTitle:hover{
  color:white;
  cursor: pointer;
}

.SidebarList .NetworkStatsTitle:hover{
  color:white;
  cursor: pointer;
}

.SidebarList .TrendingBansTitle:hover{
  color:white;
  cursor: pointer;
}

.SidebarList .PrimalWalletTitle:hover{
  color:white;
  cursor: pointer;
}

.SidebarList .SpammersTitle:hover{
  color:white;
  cursor: pointer;
}

.SidebarList .UploadTitle:hover{
  color:white;
  cursor: pointer;
}

.HomeTitle.active {
  color: white;
  text-decoration: underline;
}

.UsersTitle#active{
  color:white;
  text-decoration: underline;
}

.VerifiedUsersTitle#active{
  color:white;
  text-decoration: underline;
}

.TrendingImagesTitle#active{
  color:white;
  text-decoration: underline;
}

.NetworkStatsTitle#active{
  color:white;
  text-decoration: underline;
}

.TrendingBansTitle#active{
  color:white;
  text-decoration: underline;
}

.SpammersTitle#active{
  color:white;
  text-decoration: underline;
}

.UploadTitle#active{
  color:white;
  text-decoration: underline;
}

.PrimalWalletTitle#active{
  color:white;
  text-decoration: underline;
}

.ProfileButton {
  display: grid;
  width: 142px;
  height: 46px;
  margin-left: 80px;
  background-image: url(./Images/Rectangle\ 348.svg);
  grid-template-columns: 42px 1fr;
  grid-column-gap: 5px;
  position: fixed;
  bottom: calc(5vh + 10px);
  align-items: center;
  text-decoration: none;
}

@media (max-height: 600px){
  .ProfileButton{
    display: none;
  }
}

.ProfileInfo{
  display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: right;
    z-index: 1;
    padding: 2px;
}

.UserName{
  font-family: Roboto Flex;
  font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    color: white;
    width: 90px;
    height:20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 50px;
}

.UserVerification{
  font-family: Roboto Flex;
  font-size: 8px;
  color: #757575;
  line-height: 16px;
    font-weight: 400;
    width: 92px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 50px;
}

.ProfilePicture{
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-left: -44px;
}

.MainBody {
    flex: 1;
    background: #181818;
    width: 1000px;
    padding: 20px;
    overflow-y: scroll;
    overflow-x:hidden;
}

.verifiedUserCounts{
  margin-top: 10px;
  font-size: 20px;
  font-family: "Roboto Flex";
  margin-left: 15px;
  color: #757575;

}

.MainBody::-webkit-scrollbar{
  width:5px;
}

.MainBody::-webkit-scrollbar-thumb{
  background-color: #AAAAAA;
  border-radius: 10px;
}

.MainBody::-webkit-scrollbar-thumb:hover{
  background-color: black;
}

.Users {
  font-family: Roboto Flex;
  font-weight: 100;
  font-style: initial;
  font-size: 28px;
  line-height: 28px;
  color: #B5B5B5;
  margin-top: 45px;
  margin-left: 16px;
}

.Container1{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.Container10 {
  margin-bottom: 20px;
  display: flex;
}

.GraphContainerOne{
  width: 368px;
  height: 218px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #222;
  padding-bottom: 10px;
}

.GraphContainerTwo{
  width: 368px;
  height: 218px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #222;
  padding-bottom: 10px;
}

.GraphContainerFour{
  width: 465px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #000;
  padding-bottom: 10px;
}

.GraphContainerThree {
  width: 340px;
  height: 195px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 8px;
  background: #222;
  padding: 16px;
  margin-left: 200px;
  margin-top: -226px;
}

.ChartTitleDAUA{
  color: #757575;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 125% */
  white-space: nowrap;
  margin-top: 10px;
}

.webline{
  width: 112px;
height: 6px;
flex-shrink: 0;
border-radius: 3px;
background: #B71874;
}

.iosline{
  width: 112px;
height: 6px;
flex-shrink: 0;
border-radius: 3px;
background: #FA3D3D;
}

.androidline{
  width: 112px;
height: 6px;
border-radius: 3px;
background: #FFA030;
}

.dvmline{
  width: 112px;
height: 6px;
flex-shrink: 0;
border-radius: 3px;
background: #6C003B;
}

.ActiveUsersGraph{
  height: 320px;
  width: 100%;
  margin-top: 20px;
}

.chartplace{
  margin-top: -150px;
}

.chartplaceA{
  margin-top: 30px;
}

.chartplaceB{
  margin-top: 150px;
  width: 400px;
}

.chartplaceC{
  margin-top: -150px;
  width: 400px;
}

.GroupContainerOneHome{
  margin-left: 20px;
  width: 600px;
}

.ContainerOneHome{
  display: flex;
  justify-content: space-between;
  margin-top: -5px;
}

.ContainerTwoHome{
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.Authenticated, .UniqueIP, .NostrUnique {
  width: 280px;
  height: 165px;
  background: #222222;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Roboto Flex';
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  color: #CCCCCC5E;
  padding: 10px;
}

.Container2 {
  display: flex;
  justify-content: space-between;
}

.WebAppData, .IOS, .Android {
  width: 280px;
  height: 165px;
  background: #222222;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Roboto Flex';
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  color: #CCCCCC5E;
  padding: 10px;
}

.Authenticated:hover, .WebAppData:hover, .IOS:hover, .Android:hover {
  cursor: pointer;
}

.AuthenticatedTitle,
.UniqueIPTitle,
.NostrUniqueTitle,
.WebAppTitle,
.IOSTitle,
.AndroidTitle {
  font-family: 'Roboto Flex';
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  color: #CCCCCC5E;
  margin-top: -120px;
  margin-left:20px;
}

.Group307{
  font-family: 'Roboto Flex';
  font-size: 16px;
  font-weight: 200;
  color: #AAAAAA;
  margin-left: -50px;
  margin-top: 35px;
}

.Group309{
  font-family: 'Roboto Flex';
  font-size: 16px;
  font-weight: 200;
  color: #AAAAAA;
  margin-left: -170px;
  margin-top: 35px;
}

.Group311 {
  font-family: 'Roboto Flex';
  font-size: 16px;
  font-weight: 200;
  color: #AAAAAA;
  margin-left: -40px;
  margin-top: 35px;
}

.Group308{
  font-size: 19px;
  font-weight: 500;
  line-height: 16px;
  color: white;
  margin-left: 25px;
  margin-top: 32px;
}
.Group310{
  font-size: 19px;
  font-weight: 500;
  line-height: 16px;
  color: white;
  margin-left: 25px;
  margin-top: 32px;
}

.Group312 {
  font-size: 19px;
  font-weight: 500;
  line-height: 16px;
  color: white;
  margin-left: 25px;
  margin-top: 32px;
}

.Group313{
  font-family: 'Roboto Flex';
  font-size: 16px;
  font-weight: 200;
  color: #AAAAAA;
  margin-left: -30px;
  margin-top: 35px;
}

.Group315{
  font-family: 'Roboto Flex';
  font-size: 16px;
  font-weight: 200;
  color: #AAAAAA;
  margin-left: -20px;
  margin-top: 35px;
}

.Group317{
  font-family: 'Roboto Flex';
  font-size: 16px;
  font-weight: 200;
  color: #AAAAAA;
  margin-left: -55px;
  margin-top: 35px;
}

.Group314, .Group316, .Group318{
  font-size: 19px;
  font-weight: 500;
  line-height: 16px;
  color: white;
  margin-left: 25px;
  margin-top: 32px;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;
  text-align: start;
}

table.authenticated_table a{
  color: black;
  text-decoration: underline;
}

.authenticated_table {
  width: 100%;
  table-layout: fixed;
  padding-top: 50px;
}

.authenticated_table th,
.authenticated_table td {
  text-align: left;
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.authenticated_table th:nth-child(1),
.authenticated_table td:nth-child(1) {
  width: 60%;
}

.authenticated_table th:nth-child(2),
.authenticated_table td:nth-child(2),
.authenticated_table th:nth-child(3),
.authenticated_table td:nth-child(3),
.authenticated_table th:nth-child(4),
.authenticated_table td:nth-child(4) {
  width: 15%;
}

.authenticated_table th {
  background-color: #000000;
  font-weight: bold;
}

.authenticated_table tbody tr:nth-child(even) {
  background:rgb(170, 170, 170);
}

.table_header {
  background: black;
  text-align: left;
  font-family: Roboto;
}

.table_header:hover{
  cursor: pointer;
}

.table_row {
  background: rgb(170, 170, 170);
  color: rgb(0, 0, 0);
  font-family: Roboto;
  font-size: 13px;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

th {
  display: table-cell;
  vertical-align: inherit;
  text-align: -internal-center;
  color: white;
}

td {
  display: table-cell;
  vertical-align: inherit;
  padding-left: 5px;
}

.loader {
	height: 40px;
	width: 40px;
}

.loader span {
	display: block;
	position: absolute;
	top: 30px; left: 50px;
	bottom: 0; right: 0;
	margin: auto;
	height: 40px;
	width: 40px;
}

.loader span::before,
.loader span::after {
	content: "";
	display: block;
	position: absolute;
  color:antiquewhite;
	top: 0; left: 45px;
	bottom: 0; right: 0;
	margin: auto;
	height: 40px;
	width: 40px;
	border: 2px solid #FA3C3C;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: loader-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
	        animation: loader-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
}

@-webkit-keyframes loader-1 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1.5); opacity: 0; }
}

@keyframes loader-1 {
	0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { transform: translate3d(0, 0, 0) scale(1.5); opacity: 0; }
}

.Group408{
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: -8px;
}

.Group418{
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.Group414{
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: -8px;
}

.Group714{
  color: #FFF;
  text-align: right;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: -12px;
}

.Group715{
  color: #FFF;
  text-align: right;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 8px;
}

.Group416{
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: -8px;
}

.Group418{
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.Group412{
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: -8px;
}

.Group410{
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: -8px;
}

.TableTitles{
  font-family: Roboto Flex;
  font-size: 20px;
  color: white;
  margin-top: 17.5px;
}

.VU1{
  width: 868px;
  height: 61px;
  top: 129px;
  left: 28px;
  border-radius: 6px;
}

.VUhandle{
  width: 214px;
  height: 36px;
  top: 154px;
  left: 28px;
  border-radius: 6px;
  background: #222222;
}

.VUnpub{
  width: 535px;
  height: 36px;
  top: 154px;
  left: 249px;
  border-radius: 6px;
  background: #222222;
}

.VUaddUser{
  width: 102px;
  height: 36px;
  top: 154px;
  border-radius: 6px;
  border: 1px solid;
  border-image-source: linear-gradient(139.52deg, rgba(250, 67, 67, 0.85) 0%, rgba(91, 18, 164, 0.85) 113.49%);
  background: linear-gradient(0deg, #000000, #000000),
linear-gradient(139.52deg, #FA4343 0%, #5B12A4 113.49%),
linear-gradient(139.52deg, rgba(250, 67, 67, 0.85) 0%, rgba(91, 18, 164, 0.85) 113.49%);
}

.NostrUniqueHome, .AuthenticatedHome, .UniqueIPHome {
  width: 175px;
  height: 103px;
  background: #222222;
  border-radius: 8px;
  display: flex;
  margin-top: 7px;
  align-items: center;
}


.WebAppDataHome, .IOSHome, .AndroidHome {
  width: 175px;
  height: 103px;
  background: #222222;
  border-radius: 8px;
  display: flex;
  margin-top: 15px;
  align-items: center;
}


.AuthenticatedHomeTitle,
.UniqueIPHomeTitle,
.WebAppHomeTitle,
.IOSHomeTitle {
  color: #757575;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 125% */
  white-space: nowrap;
  margin-top: 10px;
}

.WebAppHomeTitle1{
  color: #757575;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 125% */
  white-space: nowrap;
  margin-bottom: 15px;
  margin-top: 5px;
}

.WebAppHomeTitle2{
  color: #757575;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 125% */
  white-space: nowrap;
  margin-bottom: 10px;
}

.ChartTitleDAU{
  color: #757575;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 125% */
  white-space: nowrap;
  margin-top: 10px;
}

.ChartTitleDAUB{
  color: #757575;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 125% */
  white-space: nowrap;
  margin-top: -100px;
}

.NostrUniqueHomeTitle{
  color: #757575;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 125% */
  white-space: nowrap;
  margin-top: 10px;
}

.AndroidHomeTitle {
  color: #757575;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 125% */
  white-space: nowrap;
  margin-top: 10px;
}

.Delete{
  background:rgb(170, 170, 170);
  border: none;
  padding-left: 15px;
}

.Delete:hover{
  cursor:pointer;
}

.Verified_Table{
  width: 880px;
  padding: 2px;
  margin-top: 20px;
  margin-left: 10px;
}

.HomeData{
  margin-top:12px;
}

.DisplayData{
  margin-top: 12px;
}

.AddUserForm{
  width: 875px;
  height: 61px;
  border-radius: 6px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  gap: 5px;
  margin-top: 7px;
  margin-left: 10px;
}

.SubmitAddUser{
  background-image:url(Images/addUserBtn.svg);
  width: 102px;
  height: 36px;
  border-radius: 6px;
  border: 1px;
  display: flex;
  margin-top:22px;
}

.SubmitAddUser:hover{
  cursor: pointer;
}

.AddUserNpub{
  width: 525px;
  height: 36px;
  top: 120px;
  left: 249px;
  border-radius: 6px;
  border-color: transparent;
  background: #222222;
  display: flex;
  flex-direction: column;
}

.AddUserHandle{
  width: 204px;
  height: 36px;
  top: 154px;
  left: 28px;
  border-radius: 6px;
  border-color: transparent;
  background: #222222;
  display: flex;
  flex-direction: column;
}

.label{
  color: #757575;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

input, select, textarea{
  color: #ffffff;
  font-family: Roboto Flex;
  outline: none;
  padding-left: 10px;
}

.toastHolder {
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.toastMessage {
  width: 100%;
  max-width: 400px;
  animation: fadein 0.6s;
  color: white;
  font-weight: 400;
  font-family: Roboto;
  font-size: 18px;
  line-height: 20px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-top: 12px;
  text-align: center;
}

.toastSuccess {
  background-color: green;
}

.toastWarning {
  background-color: red;
}

.toastInfo {
  background-color: purple;
}

.verifiedLink{
  text-decoration: none;
  color: black;
}

.verifiedLink:hover{
  text-decoration: underline;
}

.network_table{
  width: 100%;
}

.network_table th,
.network_table td {
  width: 10%;
  text-align: left;
  padding: 2px;
}

.UMTitle{
  color: #B5B5B5;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  line-height: 34px;
  margin-top: 16px;
  margin-left: 7px;
}

.UMTitle:hover{
  cursor: pointer;
}

.UMArrow{
  margin-left: 10px;
  margin-top: 10px;
}

.ContextMenu {
  position: absolute;
  width: 215px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  border-radius: 4px;
  border: 0.5px solid #666;
  background: #000;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.80);
  padding: 5px;
  left: 380px;
}
.ContextMenuItem {
  cursor: pointer;
  text-align: right;
  font-family: Roboto;
  padding: 6px 12px;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
  color: #CCCCCC;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}

.ContextMenuItem:hover {
  background-color: #181818;
  color: #CCCCCC;
  border-radius: 1px;
  padding: 6px 12px;
  padding-right: 30px;

}

.ContextMenuItem.selected {
  background-color: #181818;
  color: #CCCCCC;
  padding: 6px 12px 3px 3px;
  border-radius: 1px;
  padding-right: 15px;
}

.AllowListTitle{
  color: #AAA;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 50px;
  margin-left: 15px;
}

.AddUserFormUM{
  width: 647px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-left: 15px;
}

.AddUserNpubUM{
  width: 515px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 6px;
  border-color: transparent;
  background-color: #222;
  display: flex;
  flex-direction: column;
}

.SubmitAddUserUM{
  background-image:url(Images/addUserBtn.svg);
  width: 102px;
  height: 36px;
  border-radius: 6px;
  border: 1px;
  margin-top:25px;
}

.SubmitAddUserUM:hover{
  cursor: pointer;
}

label{
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  margin-bottom: 5px;
}

.AllowListTable{
  width: 868px;
  margin-top: 20px;
  margin-left: 15px;
}

.StrikeAccountBalance,
.PrimalAccountBalance{
  display: flex;
  width: 340px;
  height: 128px;
  padding: 13px 18px 15px 18px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: #222;
}

.NumberOfWallets,
.TotalTransactions,
.DailyTransactions{
  display: flex;
  width: 145px;
  height: 128px;
  padding: 13px 18px 15px 18px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 8px;
  background: #222;
}

.StrikeAccountBalanceTitle,
.PrimalAccountBalanceTitle{
  color: rgba(204, 204, 204, 0.37);
  text-align: center;
  font-family: Roboto Flex;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
}

.NumberOfWalletsTitle,
.TotalTransactionsTitle,
.DailyTransactionsTitle {
  color: rgba(204, 204, 204, 0.37);
  text-align: center;
  font-family: Roboto Flex;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  margin-bottom: 30px;
}

.GroupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.GroupContainer1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-bottom: 18px;
}

.GroupContainer1 p { /* Targets all direct children */
  margin: -1px; /* Removes all margins */
}

.GroupContainerA{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -150px;
}

.Group500 {
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: -20px;
}

.Group588{
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.Group510{
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: -40px;
}

.wallet_table {
  width: 100%;
  table-layout: fixed;
  margin-top: 20px;
}

.wallet_table th,
.wallet_table td {
  text-align: left;
  padding: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.wallet_table th:nth-child(-n+4),
.wallet_table td:nth-child(-n+4) {
  width: calc((100% - 2 * 10%) / 4);
}

.wallet_table th:nth-child(5),
.wallet_table td:nth-child(5) {
  width: 13%;
}

.wallet_table th:nth-child(6),
.wallet_table td:nth-child(6){
  width: 5%;
}

.wallet_table td:nth-child(1):hover {
  cursor: pointer;
  text-decoration: underline;
}

.wallet_table td:nth-child(6){
  text-align: right;
}


.NostrAddress{
  text-decoration: none;
  color: black;
}

.NostrAddress:hover{
  text-decoration: underline;
  cursor: pointer;
}

.NostrAddress:visited{
  color: black;
}

.ContainerOne{
  width: 950px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.UserInfo{
  width: 470px;
  height: 168px;
  border-radius: 8px;
  background: #222;
  margin-top: 12px;
  margin-left: 30px;
}

.Group600{
  color: #616161;
  font-family: Roboto flex;
  white-space: nowrap;
  font-size: 14px;
  margin-left: 30px;
  line-height: 0.5;
  padding-top: 5px;
}

.Group601{
  color: #ffffff;
  font-family: Roboto flex;
  white-space: nowrap;
  font-size: 14px;
  margin-left: 230px;
  line-height: 0.5;
  margin-top: -148px;
}


.WalletBalance{
  width: 300px;
  height: 168px;
  border-radius: 8px;
  background: #222;
  margin-top: 12px;
}

.WalletBalanceTitle{
  color: rgba(204, 204, 204, 0.37);
  text-align: center;
  font-family: Roboto Flex;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  padding-top: 15px;
}

.Group602{
  color: #FFF;
  text-align: center;
  font-family: Roboto Flex;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 10px;
}

.UserPicture{
  width: 120px;
  height: 168px;
  border-radius: 80px;
  margin-top: 8px;
  margin-left: 5px;
}

.TransactionTable{
  margin-top:15px;
}

.TransactionsTitle{
  color: white;
  font-family: Roboto Flex;
}

.transaction_table {
  width: 100%;
  table-layout: fixed;
}

.transaction_table th,
.transaction_table td {
  text-align: left;
  padding-left: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.transaction_table th:first-child,
.transaction_table td:first-child {
  width: 5%;
  text-align: center;
}

.transaction_table th:nth-child(2),
.transaction_table td:nth-child(2) {
  width: 10%;
}

.transaction_table th:nth-child(3),
.transaction_table td:nth-child(3) {
  width: 15%;
}

.transaction_table th:nth-child(4),
.transaction_table td:nth-child(4) {
  width: 20%;
}

.transaction_table th:nth-child(5),
.transaction_table td:nth-child(5) {
  width: 45%;
}

.FreezeDeleteButtons {
  width: 950px;
  display: flex;
  margin-top: 30px;
  gap: 8px;
}

.freezeWallet1 {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.freezeWallet1 img {
  width: 100%;
  height: 50px;
}

.changePubkey {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.changePubkey img {
  width: 100%;
  height: 50px;
}

.eraseWallet {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.eraseWallet img {
  width: 100%;
  height: 50px;
}

.deleteWallet {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.deleteWallet img {
  width: 100%;
  height: 50px;
}

.setMaxBalance {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.setMaxBalance img {
  width: 100%;
  height: auto;
}

.positive {
  color: green;
}

.negative {
  color: red;
}

.zero {
  color: white;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-family: Roboto Flex;
  color: white;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container label {
  margin-top: 4px;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 0.5px solid #666;
  border-radius: 3px;
}

.container:hover input ~ .checkmark {
  background-color: black;
}

.container input:checked ~ .checkmark {
  background-color: black;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.red-text {
  color: red;
}

.MaxBalance{
  margin-top: 20px;
  margin-left: 60px;
  color: white;
  font-family: Roboto Flex;
}

.inputSetMaxBalance{
  height: 30px;
  width: 135px;
  border-radius: 6px;
  border-color: transparent;
  background: #222222;
  display: flex;
  flex-direction: column;
}

.setMaxBalanceForm{
  width: 300px;
  border-radius: 6px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-left: 100px;
  margin-bottom: 10px;
}

.setMaxBalance{
  margin-top: 17px;
}

.inputSetMaxBalance {
  color: gray;
}

.inputSetMaxBalance.focused {
  color: white;
}

.TabButton {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 70px;
  border-radius: 8px 8px 0 0;
  background: #222;
  border: none;
  color: white;
  margin-right: 15px;
  text-align: center;
}

.TabButton:hover {
  cursor: pointer;
}

.ActiveTab {
  background: #000;
}

.TabButton span {
  font-size: 24px;
  color: white;
}

.TabButton p {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.LoadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #181818;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.dropdown-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.dropdown {
  flex: 1; /* Each dropdown takes equal space */
  border: none; /* Remove the border */
  background: black; /* Remove the background */
  border-radius: 5px;
  height: 36px;
}

.dropdown:hover{
  cursor: pointer;
}

.addUserToRoleButton {
  background-image: url(./Images/addUserToRole.svg);
  width: 130px;
  height: 36px;
  border-radius: 6px;
  border: 1px;
  margin-right: 140px;
}

.addUserToRoleButton:hover {
  cursor: pointer;
}

.UserRole_table {
  width: 100%;
  margin-top: 20px;
  table-layout: fixed;
}

.UserRole_table th,
.UserRole_table td {
  text-align: left;
  padding: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.UserRole_table td:nth-child(1),
.UserRole_table th:nth-child(1) {
  width: 5%;
}
.UserRole_table td:nth-child(2),
.UserRole_table th:nth-child(2) {
  width: 10%;
}

.UserRole_table td:nth-child(3),
.UserRole_table th:nth-child(3) {
  width: 50%;
}

.UserRole_table td:nth-child(5),
.UserRole_table th:nth-child(5) {
  width: 5%;
}

.AddUserFormAdmin{
  width: 647px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-top: 15px;
}

.AddUserNpubAdmin{
  width: 515px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 6px;
  border-color: transparent;
  background-color: #222;
  display: flex;
  flex-direction: column;
}

.SubmitAddUserAdmin{
  background-image:url(Images/addUserBtn.svg);
  width: 102px;
  height: 36px;
  border-radius: 6px;
  border: 1px;
  margin-top:25px;
}

.SubmitAddUserAdmin:hover{
  cursor: pointer;
}

.User_table {
  width: 100%;
  margin-top: 20px;
  table-layout: fixed;
}

.User_table th,
.User_table td {
  text-align: left;
  padding: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.User_table td:nth-child(1),
.User_table th:nth-child(1) {
  width: 5%;
}

.User_table td:nth-child(2),
.User_table th:nth-child(2) {
  width: 10%;
}

.User_table td:nth-child(4),
.User_table th:nth-child(4) {
  width: 5%;
}

.ProfilePicture1{
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.SystemTable {
  border-collapse: collapse;
  width: 100%;
}

.SystemTable td {
  padding: 10px 0px;
  border: none;
  border-bottom: 1px solid #444444;
}

.SystemTable td:first-child {
  border-left: none;
  width: 3%;
}

.SystemTable td:nth-child(2) {
  border-right: none;
}

.SystemTable td:nth-child(3){
  width: 5%;
}

.SystemName{
  color: #FFFFFF;
  font-family: Roboto Flex;
  font-size: 16px;
  padding:0px 0px;
}

.StatusCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.StatusCircle.OK {
  background-color: #66E205;
}

.StatusCircle.NotOK {
  background-color: #E20505;
}

.RefreshButton {
  background-image: url(./Images/refreshButton.svg);
  width: 141px;
  height: 36px;
  border-radius: 6px;
  border: 1px;
  margin-left: 810px;
}

.RefreshButton:hover{
  cursor: pointer;
}

.SystemDescription{
  color: #616161;
}


.SearchWallet {
  margin-top: 17px;
  background: none;
  border: none;
  color: #F800C1; /* Adjust the color to fit your design */
  font-family: "Roboto Flex";
  font-size: 14px;
  line-height: 14px; /* Keeps the font styles consistent with the surrounding text */
  cursor: pointer;
  padding: 0; /* Removes default padding */
  display: inline-flex; /* Aligns the text and the arrow icon */
  align-items: center; 
  padding: 5px;/* Centers the items vertically */
}

.SearchWallet:focus {
  outline: none; /* Removes the outline to keep the text-like appearance */
}

/* Additional styles for the expandable search section for a better appearance */
.ExpandableSearchSection {
  margin-top: 10px; /* Adds some space above the expandable section */
  gap: 10px; /* Adds space between input fields */
  width: 907px;
height: 132px;
flex-shrink: 0;
}

.UserNostrLightningSearch{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 61px;
  flex-shrink: 0;
  gap: 13px;
  margin-left: 47px;
}

.UserNameSearch{
  width: 165px;
height: 36px;
flex-shrink: 0;
border-radius: 6px;
background: #222;
display: flex;
border-color: transparent;
}

.NostrAddressSearch{
  width: 165px;
height: 36px;
flex-shrink: 0;
border-radius: 6px;
background: #222;
display: flex;
border-color: transparent;
}

.LightningAddressSearch{
  width: 165px;
height: 36px;
flex-shrink: 0;
border-radius: 6px;
background: #222;
display: flex;
border-color: transparent;
}

.CountrySearch{
  width: 178px;
height: 42px;
flex-shrink: 0;
border-radius: 6px;
background: #222;
display: flex;
border-color: transparent;
}

.OtherSearch{
  display: flex;
  flex-direction: row;
  width: 953px;
  height: 61px;
  flex-shrink: 0;
  margin-top: 10px;
  justify-content: space-between;
}

.NpubWalletSearch{
  width: 553px;
height: 36px;
flex-shrink: 0;
border-radius: 6px;
background: #222;
border-color: transparent;
}

.SubmitSearch {
  background-image:url(Images/SearchButton.png);
  width: 135px;
  height: 36px;
  border-radius: 6.5px;
  border: 1px transparent;
  display: flex;
  margin-top:25px;
}

.SubmitSearch:hover {
  cursor: pointer; /* Darker shade for hover effect */
}

.ArrowIcon {
  transition: transform 0.3s ease;
  margin-left: 4px;
}

.rotated {
  transform: rotate(180deg);
}

.CheckBoxesWallet{
  display: flex;
  flex-direction: row;
  width: 160px;
  height: 40px;
  margin-top: 25px;
  margin-right: 25px;
  justify-content: space-between;
  gap: 10px;
}

.KycCheckbox{
  margin-top: 8px;
}

.ActivatedKycCheckbox{
  margin-top: 8px;
  margin-right: 10px;
}

.balanceCheck{
  font-size: 15px;
}

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
  margin-left: 110px;/* Ensure it's on top of other content */
}

.modalContainer {
  border-radius: 8px;
  background: #222;
  width: 702px;
  height: 223px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Align children centrally in the cross axis */
  align-items: center;
}

.modalButtons {
  display: flex;
  justify-content: flex-start; /* Align buttons to the left */
  width: 100%; /* Stretch the buttons container across the modal */
  padding: 0 0 0 35px;
  gap: 20px;
}

.modalTitle {
  text-align: center;
  color: white;
  margin-bottom: 20px;
  font-family: "Roboto Flex";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; 
}

.inputModalField {
  width: 651px;
  height: 36px;
  background-color: white;
  border-radius: 6px;
  border: none;
  padding: 0 5px;
  color: black;
  font-family: Roboto Flex;
  margin-bottom: 25px; /* For some inner padding */
}

.inputModalLabel {
  display: block;
  color: white;
  text-align: left;
  margin-bottom: 5px;
  font-family: "Roboto Flex";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 17px;
}

.BanUserForm{
  width: 950px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 25px;
}

.BanUserForm label{
  gap: 5px;
}

.SearchBanUser{
  display : flex;
  width: 571px;
  height: 33px;
  border-radius: 6px;
  border: none;
  background: #FFF;
  color: #000;
  font-family: Roboto Flex;
}

.SearchBanButton, .BanSearchButton{
  background: none;
  border: none;
  margin-top: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.MediaDisplay {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(136px, 1fr)); 
  margin-top: 30px;
}

.MediaItem {
  width: 136px;
  height: 136px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MediaItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.MediaUploadDate{
  color: #B5B5B5;
font-family: "Roboto Flex";
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: 34px; /* 121.429% */
}

.NotificationBadge {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  margin-bottom: 10px;
}

/* Add this CSS to your App.css or similar stylesheet */

.contextMenu1 {
  position: absolute;
  background-color: #222;  /* Dark background for the menu */
  border: 1px solid #333;  /* Slightly lighter border */
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);  /* Darker shadow for more depth */
  z-index: 1000;
  transform: translateX(-50%); /* Center the menu horizontally */
}

.contextMenuPointer {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #222;  /* Arrow matches the menu background */
  bottom: -10px; /* Position the arrow at the bottom */
  left: 50%;
  transform: translateX(-50%);
}

.contextMenuContent {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.contextMenuContent button {
  background: #222;
  border: 0.1px solid #444;   /* Slightly lighter shade for buttons */  /* Slight border for definition */
  padding: 8px 12px;
  cursor: pointer;
  text-align: left;
  color: #fff;  /* White text */
  border-radius: 3px;  /* Slight rounding to soften the look */
}

.contextMenuContent button:hover {
  background-color: #444;  /* Lighter shade on hover */
  border-color: #555;  /* Further lighten the border on hover */
}

.latest-subscribers-title {
  color: #757575;
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 95px;
  font-family: Roboto Flex;
  margin-top: -1px;
}

.latest-subscribers-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  width: 48%; /* Allows two columns to fit nicely */
}

.subscriber {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.avatar {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 8px;
}

.subscriber-name {
  color: #AAAAAA;
  font-size: 13px;
  margin-right: 6px;
  font-family: Roboto Flex;
  margin-top:2px;
}

.subscriber-time {
  color: #777;
  font-size: 12px;
  font-family: Roboto Flex;
}

.PremiumHeader{
  width: 1000px;
}

.PremiumCardZone {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.PremiumCardsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.PremiumCard {
  background-color: #222222;
  border-radius: 8px;
  width: 175px;
  height: 70px;
  padding: 20px;
  text-align: center;
  color: #ffffff;
  margin-right: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.PremiumCard:last-child {
  margin-right: 0;
}

.PremiumCardTitle {
  font-size: 16px;
  color: #757575;
  margin-bottom: 10px;
  font-family: Roboto Flex;
  white-space: nowrap;
}

.PremiumCardValue {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  font-family: Roboto Flex;
}

.PremiumPageButtons{
  margin-left: 200px;
  display: flex;
  margin-top: -35px;
}

.ReservedNamesButton, .PromoCodesButton, .ChangeNameButton, .RefreshButtons, .ExpiredButton, .LegendsButton{
  background: none;
  border: none;
  width: 125px;
}

.ReservedNamesButton:hover, .PromoCodesButton:hover, .ChangeNameButton:hover, .RefreshButtons:hover, .ExpiredButton:hover, .LegendsButton:hover{
  cursor: pointer;
}

.LatestSubscriberLink{
  text-decoration: none; /* Remove underline */
  color: inherit; 
  display: flex;
  margin-top: 2px;
}

.SubscriptionList {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between rows */
  margin-top: 20px;
}

.SubscriptionRow {
  display: flex;
  align-items: center;
  background-color: #383938;
  border-radius: 8px;
  padding: 10px;
  gap: 2px; /* Space between items in a row */
  font-family: 'Roboto', sans-serif; /* Apply Roboto font to all rows */
}

.RowItem {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #FFF;
  flex: 1; /* Equal space for each item */
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Ensure long text doesn't break layout */
  text-overflow: ellipsis; /* Add ellipsis if text is too long */
}

.DateRowItem {
  flex: 3;
  text-align: left; /* Give extra space to the date row item */
}

.NameRowItem{
  flex: 2;
text-decoration: none;
}

.NameLink {
  text-decoration: none; /* Removes underline from link */
  color: inherit; /* Inherits text color */
  font-weight: bold; /* Optional: Make the name bold */
}



.Avatar {
  flex: 0 0 50px; /* Fixed width for avatar section */
}

.avatar-img {
  width: 28px;
  height: 28px;
  border-radius: 50%; /* Circular avatars */
  object-fit: cover;
}

.lgd-row {
  font-family: "Roboto Flex", sans-serif !important; /* Ensure LGD font is applied */
  color: #FFA02F; /* Ensure LGD color is applied */
  font-weight: bold; /* Bold font for LGD rows */
}

.lgd-row .RowItem {
  font-family: "Roboto Flex", sans-serif !important; /* Apply to each RowItem */
  color: #FFA02F; /* Apply gold font to all RowItems in LGD rows */
}





.createBatchFormHorizontal {
  margin-top: 40px;
  color: #fff;
}

.formTitle {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: Roboto Flex;
  color: #AAAAAA;
}

.formFields {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-top: 25px;
}

.formColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formColumn label {
  margin-bottom: 5px;
  font-size: 14px;
  font-family: Roboto Flex;
  color: #757575;
}

.batchNameInput {
  width: 141px;
  height: 35px;
  background-color: #222222;
  border: none;
  border-radius: 6px;
}

.codesInput {
  width: 80px;
  height: 35px;
  background-color: #222222;
  border: none;
  border-radius: 6px;
}

.discountInput {
  width: 90px;
  height: 35px;
  background-color: #222222;
  border: none;
  border-radius: 6px;
}

.expirationInput {
  width: 176px;
  height: 35px;
  background-color: #222222;
  border: none;
  border-radius: 6px;
}

.labelInput {
  width: 153px;
  height: 35px;
  background-color: #222222;
  border: none;
  border-radius: 6px;
}

.skuSelect {
  width: 94px;
  height: 35px;
  background-color: #222222;
  border: none;
  border-radius: 6px;
}

.generateButton {
  align-self: flex-end;
  border: none;
  cursor: pointer;
  background: none;
}

.generateButtonImage{
  margin-bottom: -4px;
  margin-left: -4px;
}

/* Style for the table */
.existingBatchesTable {
  margin-top: 30px;
  width: 100%;
}

.customTable {
  width: 100%;
  border-collapse: collapse;
}

.customTable thead {
  background-color: #000;
}

.customTable thead th {
  color: #fff;
  padding: 5px;
  font-family: Roboto Flex;
  text-align: left;
  font-size: 14px;
}

.customTable tbody {
  background-color: #AAAAAA;
}

.customTable tbody td {
  padding: 5px;
  border: 2px solid #000;
  font-family: Roboto Flex;
  font-size: 14px;
}

.statusDot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.statusDot.green {
  background-color: #3FBF3F;
}

.deleteButton {
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  font-size: 16px;
}

.deleteButton:hover {
  color: #f00;
}

.InputContainerRN {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Spacing between input fields */
  margin-top: 40px;
  margin-left: 5px;
}

.InputGroup {
  display: flex;
  flex-direction: column;
}

.InputWithButton {
  display: flex;
  align-items: center;
  gap: 5px; /* Small spacing between input and button */
}

.InputLabel {
  color: #757575;
  font-size: 14px;
  margin-bottom: 8px; /* Reduced space between label and input */
}

.CustomInput {
  width: 216px;
  height: 23px;
  border: none;
  border-radius: 6px;
  background-color: #222222;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  outline: none;
}

.CustomInput::placeholder {
  color: #757575; /* Optional placeholder color */
}

.IconButton {
  width:102px;
  height: 30px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.ButtonImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ReservedNamesTable {
  width: 40%;
  border-collapse: collapse;
  margin-top: 20px;
}

.ReservedNamesTable th, .ReservedNamesTable td {
  border: 1px solid ;
  padding: 6px;
  text-align: left;
  font-family: Roboto Flex;
  font-size: 14px;
}

.ReservedNamesTable td {
  background-color: #AAAAAA;

}

.ReservedNamesTable th {
  background-color: black;
  color: white;
  font-size: 16px;
  border: none;
}

.DeleteReserveButton{
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 12px;
}

.InputContainerCN {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.InputGroupCN {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.NpubCN{
  width: 462px;
  height: 36px;
  border-radius: 6px;
  background-color: #222222;
  color: white;
  border: none;
}

.newNameCN{
  width: 249px;
  height: 36px;
  border-radius: 6px;
  background-color: #222222;
  color: white;
  border: none;
}

.ChangeNameButtons{
  display: flex;
  flex-direction: column;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 50px;
}

.ClickableName{
  cursor: pointer;
}

.AvatarCN{
  display: flex;
  flex-direction: column;
  height: 40px;
  margin-bottom: 6px;
  border-radius: 45px;
}

.ActivateManuallyButton{
  display: flex;
  flex-direction: column;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 810px;
}

.ActivateManuallyForm {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust spacing between the inputs and button */
  margin-top: 20px;
}

.ActivateManuallyInputContainer {
  display: flex;
  flex-direction: column;
}

.ActivateManuallyLabel {
  color: #757575;
  font-size: 14px;
  margin-bottom: 5px;
  font-family: "Roboto";
}

.ActivateManuallyInput {
  width: 400px;
  height: 36px;
  background-color: #222222;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0 10px;
  font-size: 14px;
}

.ActivateManuallyButtons {
  border: none;
  cursor: pointer;
  background: none;
  margin-top: 23px;
  margin-left: -3px;
}

.UserDisplay {
  display: flex;
  align-items: center;
  margin-top: 20px;
  background-color: #222222;
  width: 500px;
  height: 128px;
  border-radius: 8px;
  padding: 10px;
  gap: 15px;
  margin-left: 210px;
}

.UserAvatar {
  width: 120px;
  height: 120px;
  border-radius: 60px;
}

.UserDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.DetailRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.DetailLabel {
  color: #616161;
  font-size: 14px;
  font-family: "Roboto Flex";
  flex-basis: 40%;
  white-space: nowrap;
}

.DetailValue {
  color: #FFFFFF;
  font-size: 14px;
  flex-basis: 60%;
  text-align: right;
  font-family: "Roboto";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MonthlyRevenueZone{
  display: flex;
  width: 100%;
  height: 183px;
  margin-top: 20px;
}

.MonthlyRevenues{
  width: 378px;
  height: 183px;
  background-color: #222222;
  border-radius: 8px;
}

.MonthlyRevenuesTitle{
  color: #757575;
  font-family: "Roboto";
  font-size: 16px;
  display: flex;
  margin-top: 15px;
  margin-left: 120px;
  white-space: nowrap;
}

.MontlyRevenueTitles{
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-left: 20px;
  margin-top: 10px;
}

.MonthlyRevenueTitle{
  font-family: "Roboto";
  font-size: 18px;
  color: #FFFFFF;
}

.TotalMonthlyRevenueList{
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-left: 20px;
  margin-top: 10px;
}

.TotalMonthlyRevenue{
  font-family: "Roboto Flex";
  font-size: 18px;
  color: #FFFFFF;
}

.LegendMonthlyRevenueList{
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-left: 20px;
  margin-top: 10px;
}

.LegendMonthlyRevenue{
  font-family: "Roboto";
  font-size: 18px;
  color: #FFA030;
}

.PremiumMonthlyRevenueList{
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-left: 20px;
  margin-top: 10px;
}

.PremiumMonthlyRevenue{
  font-family: "Roboto";
  font-size: 18px;
  color: #F800C1;
}

.MonthlyRevenueGraph{
  width: 380px;
  margin-top: 20px;
  margin-right: 150px;
}

.LHeaders{
  display: flex;
  flex-direction: row;
  gap: 110px;
  margin-top: 40px;
  margin-left: 40px;
}

.LHeader1{
  color: white;
  font-family: Roboto Flex;
  font-size: 20px;
  margin-left: -40px;
}

.LHeader2{
  color: white;
  font-family: Roboto Flex;
  font-size: 20px;
  margin-left: 73px;
}

.LHeader3{
  color: white;
  font-family: Roboto Flex;
  font-size: 20px;
  margin-left: -10px;
}

.LHeader4{
  color: white;
  font-family: Roboto Flex;
  font-size: 20px;
  margin-left: 10px;
}

.LHeader5{
  color: white;
  font-family: Roboto Flex;
  font-size: 20px;
  margin-left: 10px;
}

.LHeader7{
  color: white;
  font-family: Roboto Flex;
  font-size: 20px;
  margin-left: 10px;
}

.DateRowItem2 {
  flex: 3;
  text-align: left;
  margin-left: 10px; /* Give extra space to the date row item */
}

.DateRowItem3 {
  flex: 4;
  text-align: left; 
  margin-left: -40px;/* Give extra space to the date row item */
}

.OriginRowItem{
  margin-left: -100px;
  margin-right: 90px;
}

.ReasonRowItem{
  text-align: left;
  margin-right: 40px;
}

.LHeadersLegends{
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 40px;
  margin-left: 40px;
}

.custom-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: black;
  border: 0.5px solid white;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-checkbox input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.custom-checkbox .checkmark {
  position: absolute;
  width: 14px;
  height: 5px;
  margin-top: 4px;
  margin-left: 1px;
  border: solid white;
  border-width: 0 0 3px 3px;
  transform: rotate(-45deg);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.custom-checkbox input:checked + .checkmark {
  opacity: 1;
}

.LLHeadersLegends{
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 30px;
  margin-left: 40px;
  margin-bottom: -10px;
}

.LLHeader1{
  color: white;
  font-family: Roboto Flex;
  font-size: 16px;
  margin-left: -40px;
}

.LLHeader2{
  color: white;
  font-family: Roboto Flex;
  font-size: 16px;
  margin-left: 140px;
}

.LLHeader3{
  color: white;
  font-family: Roboto Flex;
  font-size: 16px;
  margin-left: 47px;
}

.LLHeader4{
  color: white;
  font-family: Roboto Flex;
  font-size: 16px;
  margin-left: 35px;
}

.LLHeader5{
  color: white;
  font-family: Roboto Flex;
  font-size: 16px;
  margin-left: 1px;
}

.LLHeader6{
  color: white;
  font-family: Roboto Flex;
  font-size: 16px;
  margin-left: 15px;
}

.LLHeader7{
  color: white;
  font-family: Roboto Flex;
  font-size: 16px;
}

.SubscriptionRowLegends {
  display: grid;
  grid-template-columns: 
    5%   /* Avatar */
    16%  /* Name */
    11%  /* Sats Donated */
    15%  /* Badge */
    8%  /* Ring */
    8%  /* Leaderboard */
    7%   /* Theme */
    5%
    5%  
    3%
    auto; /* Remaining space if any */
  
  align-items: center;
  background-color: #383938;
  border-radius: 8px;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  gap: 10px; /* Space between items */
  color: white;
}

.EditRowItem{
 color: #FFA030;
 text-decoration: underline;
 font-family: Roboto Flex;
 cursor: pointer;
}

.BadgeBox, .RingBox, .LeaderBoardBox{
  margin-bottom: -4px;
}

.PopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.PopupContent {
  width: 570px;
  height: 380px;
  background: #222222;
  border-radius: 8px;
  padding: 20px;
  color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-columns: 2fr 1fr; /* Two columns: Textboxes on the left, checkboxes & themes on the right */
  column-gap: 20px;
}

.PopupTitle {
  font-family: Roboto Flex;
  font-size: 18px;
  grid-column: 1 / -1; /* Spans both columns */
  margin-bottom: 20px;
}

.PopupLabel {
  font-family: Roboto;
  font-size: 14px;
  margin-bottom: 10px;
}

textarea {
  width: 299px;
  height: 80px;
  background: #171717;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 6px;
}

.PopupSection {
  margin-bottom:10px;
}

.PopupOptions {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: -10px;
}

.PopupCheckboxes{
  display: flex;
  gap: 30px;
  margin-top: 25px;
  margin-right: 20px;
}

.BadgePopup{
  display: flex;
  gap: 8px;
  font-family: Roboto Flex;
  font-size: 16px;
}

.LeaderboardPopup{
  display: flex;
  gap: 8px;
  margin-left: 15px;
  font-family: Roboto Flex;
  font-size: 16px;
}

.ThemePopup{
  font-family: Roboto Flex;
}

.ThemeSelection {
  margin-top: 10px;
}

.ThemeColors {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.ThemeColors1{
  display: flex;
  gap: 15px;
  margin-top: 10px;
  margin-left: 20px;
}

.ColorCircle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #777;
  cursor: pointer;
}

.PopupActions {
  display: flex;
}

.PopupButton{
  background: none;
  border: none;
  cursor: pointer;
}

.clickable{
  cursor:pointer;
}

.DatePickerSection{
  margin-top: 10px;
  width: 200px;
}

.CloseButton{
  margin-left: 550px;
  display: flex;
  margin-top: -17px;
  cursor: pointer;
}

.dateText{
  color: black;
}

